import React, { Fragment, useRef, useState } from 'react'

import Pagina from '../components/Pagina'
import { graphql, Link } from 'gatsby'
import ReactPlayer from 'react-player'
import { GatsbyImage } from 'gatsby-plugin-image'
import Swiper from 'react-id-swiper'

// components
import Layout from '../components/Layout'
import SectionBlogPost from '../components/SectionBlogPost/sectionBlogPost'
import Category from '../components/Category/category'
import CardOferta from '../components/cardOfertas/cardOfertas'
import Instagram from '../components/Instagram/instagram'
import CategoryMobile from '../components/Category/categoryMobile'
import ValidDate from '../components/validDate/validDate'

// styles
import './styles/blog.scss'

const PostMaior = (props) => {
  return (
    <div className='col-12 p-0 pb-sm-5'>
      {
        props.data.edges.map(post => {
          return <>
            <div className='video'>
              {post.node.imagemdestaque !== null &&
                <GatsbyImage
                  image={post.node.imagemdestaque.localFile.childImageSharp.gatsbyImageData}
                  alt={props.slug}
                  className='w-100 h-100' />
              }
              {post.node.urlVideo !== null &&
                <ReactPlayer url={post.node.urlVideo} width='100%' height='100%'/>
              }
            </div>
            <div className='title-blog mt-2 col-12 p-0'>
              <ValidDate date={props.data.edges[0].node.date} />
              <h2 className='mt-2 m-0 p-0'>{post.node.title}</h2>
              <p className='text-blog'>{post.node.subtitle}</p>
              <Link to={`/blog/${post.node.slug}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase mt-2 mb-4'>
                Leia Mais
              </Link>
            </div>
          </>
        })
      }
    </div>
  );
}

const OtherPosts = (props) => {
  return (
    <div className='row pb-4 d-none d-sm-flex'>
      {
        props.data.edges.map(post => {
          const date = new Date(post.node.date)
          return (
            <div className='col-12 col-sm-6'>
              <div className='sizeimg-post-blog'>
                <GatsbyImage
                  image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                  alt={post.node.slug}
                  className='w-100 h-100' />
              </div>
              <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase circle-blog'>
                <p className='text-capitalize'>{date.getDate()}</p>
                <p className='text-uppercase'>{props.mouths[date.getMonth()]}</p>
              </div>
              <div className='title-blog mt-2 col-12 p-0'>
                <ValidDate date={post.node.date} />
                <h2 className='mt-2'>{post.node.title}</h2>
                <p className='text-blog'>{post.node.subtitle}</p>
                <Link to={`/blog/${post.node.slug}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase mt-4 mb-4'>
                    Leia Mais
                </Link>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

const OtherPostsMobile = (props) => {
  return <>
    <div className='row pt-4'>
      {
        props.data.edges.map((post, index) => {
          const date = new Date(post.node.date)
          return (
            <div className='col-xl-4 col-lg-6 pb-4 d-flex d-sm-none justify-content-between' key={index}>
              <div className='col-6 col-sm-12 p-0 sizeimg-post-blogMobile'>
                <GatsbyImage
                  image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                  alt={post.node.slug}
                  className='w-100 h-100' />
                <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase circle-blogMobile'>
                  <p className='text-capitalize'>{date.getDate()}</p>
                  <p className='text-uppercase'>{props.mouths[date.getMonth()]}</p>
                </div>
              </div>
              <div className='caption-blogMobile col-6 col-sm-12 pl-3 p-0'>
                <p className='font-weight-bold pt-0 pt-sm-3 pb-0'>{post.node.category.type}</p>
                <h2 className='pb-1'>{post.node.title}</h2>
                <Link to={`/blog/${post.node.slug}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase w-100'>
                  Leia Mais
                </Link>
              </div>
            </div>
          )
        })
      }
    </div>
  </>
}

const CustomSectionBlogMobile = (props) => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  }

  return (
    <div className='bg-gray'>
      <h2 className='pt-2'>Blog Do Spani</h2>
      <div id='carosel-container_settings' className='mb-4 pb-2'>
        <Swiper {...params}>
          {
            props.data.edges.map((post, index) => {
              const date = new Date(post.node.date)

              return (
                <div key={index}>
                  <div className='sizeimg-post-blog'>
                    <GatsbyImage
                      image={post.node.card.localFile.childImageSharp.gatsbyImageData}
                      alt={post.node.slug}
                      className='w-100 h-100' />
                  </div>
                  <div className='position-absolute d-flex justify-content-center alignt-items-center flex-column text-center text-uppercase circle-blog'>
                    <p className='text-capitalize'>{date.getDate()}</p>
                    <p className='text-uppercase'>{props.mouths[date.getMonth()]}</p>
                  </div>
                  <div className='title-blog mt-2 col-12 p-0'>
                    <ValidDate date={post.node.date} />
                    <h2 className='mt-2 m-0 p-0'>{post.node.title}</h2>
                    <Link to={`/blog/${post.node.slug}/`} className='btn btn-blog d-flex justify-content-center align-items-center text-center text-uppercase mt-2 mb-4'>
                      Leia Mais
                    </Link>
                  </div>
                </div>
              );
            })
          }
        </Swiper>
      </div>
    </div>
  );
}

const Blog = (props) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/blog/', name: 'Blog do Spani' }
    ]
  }

  const [currentMarket, setCurrentMarket] = useState(typeof window !== 'undefined' ? JSON.parse(window.localStorage.getItem('currentMarket')) : null)
  const modalRef = useRef()

  const mouths = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]

  return (
    <Fragment>
      <Pagina pagina={props.data.pagina} />
      <Layout breadCrumb={breadC} homeCallback={setCurrentMarket} ref={modalRef}>
        <div className='bg-gray mb-5 d-none d-sm-block'>
          <SectionBlogPost />
        </div>
        <div className='col-12 mt-4'>
          <CategoryMobile />
        </div>
        <div className='bg-gray d-block d-sm-none col-12'>
          <CustomSectionBlogMobile data={props.data.customPosts} mouths={mouths}/>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-8'>
              <PostMaior data={props.data.postMaior} />
              <OtherPosts data={props.data.others} mouths={mouths} />
              <OtherPostsMobile data={props.data.others} mouths={mouths} />
            </div>
            <div className='col-12 mt-2 mb-2 d-block d-lg-none'>
              <hr/>
            </div>
            <div className='col-12 col-lg-4'>
              <h2 className='font-baloo-extrabold d-none d-lg-block'>Categorias</h2>
              <Category path = 'blog'></Category>
            </div>
            <div className='col-12 mt-2 mb-2 d-block d-lg-none'>
              <hr/>
            </div>
          </div>
        </div>
        {props.data.infoCards.edges.length !== 0 && <CardOferta modal={modalRef} current={currentMarket} info={props.data.infoCards} />}
        <Instagram />
      </Layout>
    </Fragment>
  )
}
export default Blog

export const query = graphql`query ($date: Date) {
  pagina: contentfulPagina(path: {eq: "/blog/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
  }
  infoCards: allContentfulOfertas(
    filter: {dataInicio: {lte: $date}, dataFim: {gte: $date}}
  ) {
    edges {
      node {
        nome
        dataInicio
        dataFim
        ofertasCards {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  postMaior: allContentfulBlog(
    skip: 3
    limit: 1
    sort: {fields: date, order: DESC}
  ) {
    edges {
      node {
        slug
        title
        date
        subtitle
        urlVideo
        card {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        imagemdestaque {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  others: allContentfulBlog(skip: 4, limit: 2, sort: {fields: date, order: DESC}) {
    edges {
      node {
        slug
        title
        subtitle
        date
        card {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        category {
          type
        }
      }
    }
  }
  customPosts: allContentfulBlog(sort: {fields: date, order: DESC}, limit: 3) {
    edges {
      node {
        slug
        title
        subtitle
        date
        card {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        category {
          type
        }
      }
    }
  }
}
`
